/**
 * Import and register all global components
 */

import Vue from "vue";

import RequestButton from "./RequestButton";
Vue.component("request", RequestButton);

import RequestForm from "./RequestForm";
Vue.component("request-form", RequestForm);

import Dropdown from "./Dropdown";
Vue.component("dropdown", Dropdown);

import Panel from "./Panel";
Vue.component("panel", Panel);

import Modal from "./Modal";
Vue.component("modal", Modal);

import Checkin from "./Checkin";
Vue.component("checkin", Checkin);

import Datepicker from "./Datepicker";
Vue.component("datepicker", Datepicker);

import Bodymap from "./Bodymap";
Vue.component("bodymap", Bodymap);

import BodymapDataPoint from "./BodymapDataPoint";
Vue.component("bodymapdatapoint", BodymapDataPoint);

import TimeDisplay from "./TimeDisplay";
Vue.component("time-display", TimeDisplay);

import RotaList from "./RotaList";
Vue.component("rota-list", RotaList);

import RotaTimeline from "./RotaTimeline";
Vue.component("rota-timeline", RotaTimeline);

import Rota from "./Rota";
Vue.component("rota-view", Rota);

import RotaGenerator from "./RotaGenerator";
Vue.component("rota-generator", RotaGenerator);

import Chart from "./Chart";
Vue.component("chart", Chart);

import ImagePreview from "./ImagePreview";
Vue.component("imgepreview", ImagePreview);

import Durationpicker from "./Durationpicker";
Vue.component("Durationpicker", Durationpicker);

import RotaRequestButton from "./RotaRequestButton";
Vue.component("rota-request", RotaRequestButton);

import VueTextarea from "./VueTextarea";
Vue.component("vuetextarea", VueTextarea);