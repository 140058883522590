import {PJAX_ELEMENTS} from "util/pjax";

let lastHref = null;
export default function(parent) {
  parent = parent ? parent : document.body;

  let elements = parent.querySelectorAll(PJAX_ELEMENTS);
  elements = Array.prototype.slice.call(elements, 0);

  elements.forEach(function(element) {
    if (element.pjax) {
      return;
    }
    element.pjax = true;
    element.addEventListener("click", function() {
      lastHref = this.href;
      if (!lastHref) {
        lastHref = this.action;
      }
    }, true);
  });
}

document.addEventListener("pjax:error", function() {
  if (lastHref) {
    window.location = lastHref;
  }
});
