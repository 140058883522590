/**
 * DropDown Component
 * Replaces the standard <select> HTML component with a custom
 * stylable element
 */

import Vue from "vue";

import template from "templates/Dropdown.html";

export default Vue.extend({
  "props": {
    "name": {
      "type": String,
      "required": true
    }
  },
  "data": function() {
    return {
      "options": [],
      "selected": 0,
      "classes": {
        "dropdown--open": false
      },
      "styles": {

      },
      "chosen": ""
    };
  },
  "template": template,
  "ready": function() {
    document.body.addEventListener("click", () => {
      const cls = Vue.util.extend({}, this.classes);
      cls["dropdown--open"] = false;
      this.classes = cls;
    });

    const options = [].slice.call(this.$el.querySelectorAll("option"), 0);
    const items = [];
    options.forEach((item, index) => {
      const label = item.textContent;
      let value = item.getAttribute("value");
      if (!item.hasAttribute("value")) {
        value = label;
      }

      items.push({
        "label": label,
        "value": value,
        "index": index
      });

      if (item.hasAttribute("selected")) {
        this.$set("selected", index);
        this.$set("chosen", value);
      }

    });
    this.$set("options", items);
    //problems in modal
    setTimeout(() => {
      const bounds = this.$el.getBoundingClientRect();
      if (bounds.width > 0) {
        this.$set("styles.width", bounds.width + "px");
      }
    }, 10);
  },
  "methods": {
    "toggleList": function() {
      const cls = Vue.util.extend({}, this.classes);

      cls["dropdown--open"] = !cls["dropdown--open"];
      this.classes = cls;
    },
    "select": function(option, event) {
      if (!this.classes["dropdown--open"]) {
        return;
      }
      this.$set("selected", option.index);
      this.$set("chosen", option.value);
      setTimeout(() => {
        this.$emit("change", event);
      }, 50);
    }
  }
});
