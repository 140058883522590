/**
 * Datepicker Component
 *
 */

import Vue from "vue";
import flatpickr from "flatpickr";
import template from "templates/Datepicker.html";

export default Vue.extend({
  "props": {
    "name": {
      "type": String,
      "required": true
    },
    "class": {
      "type": String,
      "required": false
    },
    "placeholder": {
      "type": String,
      "required": false
    },
    "value": {
      "type": String,
      "required": false
    },
    "format": {
      "type": String,
      "required": false,
      "default": "d/m/Y"
    },
    "maxdate": {
      "type": String,
      "required": false,
      "default": ""
    },
    "mindate": {
      "type": String,
      "required": false,
      "default": ""
    },
    "enabletime": {
      "type": String,
      "required": false,
      "default": "false"
    },
    "enableseconds": {
      "type": String,
      "required": false,
      "default": "false"
    },
    "nocalendar": {
      "type": String,
      "required": false,
      "default": "false"
    }
  },
  "template": template,
  "ready": function() {
    flatpickr(this.$el, {
      dateFormat: this.format,
      enableTime: this.enabletime,
      enableSeconds: this.enableseconds,
      noCalendar: this.nocalendar,
      maxDate: new Date(this.maxdate),
      minDate: new Date(this.mindate),
      onChange: function(selectedDate) {
        const mid_week_expiry_date = document.getElementsByName("mid_week_expiry_date")[0];
        if (typeof(mid_week_expiry_date) !== "undefined" && mid_week_expiry_date !== null) {
          document.getElementsByClassName("custom_package_expiry_date_wrapper")[0]
            .style.display = "block";
          const expiryDate = new Date(mid_week_expiry_date.value);
          if(selectedDate > expiryDate) {
            const first = selectedDate.getDate() - selectedDate.getDay() + 1;
            const last = first + 6;

            const firstDay = new Date(selectedDate.setDate(first));
            const lastDay = new Date(selectedDate.setDate(last));

            document.getElementsByName("start_date")[0].value=firstDay.getDate()+"/"+
              (firstDay.getMonth()+1)+"/"+firstDay.getFullYear();
            document.getElementsByClassName("custom_package_expiry_date")[0].innerText = lastDay
              .getDate()+"/"+(lastDay.getMonth()+1)+"/"+lastDay.getFullYear();
            document.getElementsByClassName("repeat_custom_package")[0].style.display = "block";
          } else {
            document.getElementsByClassName("custom_package_expiry_date")[0].innerText = expiryDate
              .getDate()+"/"+(expiryDate.getMonth()+1)+"/"+expiryDate.getFullYear();
            document.getElementsByClassName("repeat_custom_package")[0].style.display = "none";
          }
        }
      }
    });
  }
});
