/**
 * Bodymap Component
 *
 */

import Vue from "vue";
import template from "templates/Bodymap.html";

export default Vue.extend({
  "props": {
  },
  "methods": {
    findPosition: function(el) {
      if(typeof( el.offsetParent ) !== "undefined"){
        let posX = 0, posY = 0;
        for ( posX; el; el = el.offsetParent){
          posX += el.offsetLeft;
          posY += el.offsetTop;
        }
        return [ posX, posY ];
      }else{
        return [el.x, el.y];
      }
    },
    getCoordinates: function(event) {
      let posX = 0;
      let posY = 0;
      const element = event.target;
      const imgPos = this.findPosition(element);
      const e = event || window.event;
      if (e.pageX || e.pageY) {
        posX = e.pageX;
        posY = e.pageY;
      } else if (e.clientX || e.clientY) {
        posX = e.clientX + document.body.scrollLeft +
          document.documentElement.scrollLeft;
        posY = e.clientY + document.body.scrollTop +
          document.documentElement.scrollTop;
      }
      const imageX = posX - imgPos[0];
      const imageY = posY - imgPos[1];
      document.getElementsByName("bodymap_x")[0].value = imageX;
      document.getElementsByName("bodymap_y")[0].value = imageY;
      if(document.getElementsByClassName("form__imagepreview--image")[0]){

        document.getElementsByClassName("form__imagepreview--image")[0]
              .setAttribute("src","/images/default_image.gif");
      }
      this.$root.showBodyMap =true;
      if(document.getElementsByClassName("datapoint__hidden")[0]){
        const tempDataPoint = document
          .getElementsByClassName("datapoint__hidden")[0];
        tempDataPoint.style.left = (imageX - 8) + "px";
        tempDataPoint.style.top = (imageY - 8) + "px";
        tempDataPoint.click();
      }
    },
    drawBodymapPoints: function() {
      const bodymapItems = document.getElementsByName("bodymap-item");
      for (let i = 0; i < bodymapItems.length; i++) {
        bodymapItems[i].style.left = bodymapItems[i]
          .getAttribute("data-x") - 8 + "px";
        bodymapItems[i].style.top = bodymapItems[i]
          .getAttribute("data-y") - 8 + "px";
      }
    },
  },
  "template": template,
  "ready": function() {
    this.drawBodymapPoints();
  }
});