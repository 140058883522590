import Vue from "vue";
import template from "../templates/TimeDisplay.html";
import moment from "moment";

const TOTAL_TIME = 1440;

export default Vue.extend({
  template,
  props: {
    day: Number,
    editable: Boolean,
    slots: {

      type: Array,
      default: [],
    }
  },
  data() {
    return {
      chunks: [],
    };
  },
  computed: {
    dayOfWeek() {
      return moment.weekdays(this.day);
    },
    inputs() {
      const out = [];
      let current = null;
      for (const [i, on] of this.chunks.entries()) {
        if (!on && current) { // Slot ended
          current.push((i - 1)*30);
          out.push(current);
          current = null;
        } else if (on && !current) { // Slot started
          current = [i*30];
        }
      }
      if (current) {
        current.push(TOTAL_TIME);
        out.push(current);
      }
      return out;
    },
    timeSlots() {
      return this.chunks.map((on, i) => {
        const from = i * 30;
        const to = from + 30;
        const start = Math.round(10000 * (from / TOTAL_TIME)) / 100;
        const end = Math.round(10000 * (to / TOTAL_TIME)) / 100;

        return {
          left: start + "%",
          width: (end - start) + "%",
          from,
          to,
          display: on ? "block" : "none",
        };
      });
    }
  },
  filters: {
    time(minutes) {
      const date = moment().startOf("day").add(minutes, "minutes");
      return date.format("HH:mm");
    }
  },
  methods: {
    toggleSlot(slot) {
      console.log(slot, this.chunks[slot]);
      if (!this.editable) {
        return;
      }
      const chunks = [...this.chunks];
      chunks[slot] = !this.chunks[slot];
      this.$set("chunks", chunks);
    },
  },
  ready() {
    outer:
    for (let i = 0; i < 48; i++) {
      const minute = i * 30;
      for (const { from, to } of this.slots) {
        if (from <= minute && to >= minute) {
          this.chunks.push(true);
          continue outer;
        }
      }
      this.chunks.push(false);
    }
  },
});
