import Pjax   from "pjax";

// Choose page elements to replace
export const PJAX_ELEMENTS = "a[href]";

export default new Pjax({
  "elements": PJAX_ELEMENTS,
  "selectors": [
    "title",
    ".content",
    ".navigation"
  ],
  "switches": {
    ".navigation": function(oldEl, newEl) {
      // Use innerHTML to preserve current menu state
      oldEl.innerHTML = newEl.innerHTML;
      this.onSwitch();
    }
  }
});
