import flatpickr from "flatpickr";
import template from "../templates/DateTimePicker.html";

import Vue from "vue";
import moment from "moment";

export default Vue.extend({
  template,
  props: {
    value: {
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    config: {
      default: {},
    },
    flatpickr: {
      default: null,
    },
  },
  data() {
    return {
      time: null,
      ignore: false,
    };
  },
  methods: {
    update(e) {
      this.$dispatch("change", e, this.name);
    },
  },
  watch: {
    value(v) {
      if (!this.flatpickr || !v) {
        return;
      }
      const setDate = moment(v).toDate();
      const setMinutes = moment(v).toDate();

      this.flatpickr.setDate(setDate, false);
      this.flatpickr.secondElement.value = setMinutes.getSeconds();
      this.flatpickr.setTime(setMinutes.getHours(), setMinutes.getMinutes());
    },
  },
  computed: {
    val() {
      if (!this.value) {
        return "";
      }
      return moment(this.value).format("YYYY-MM-DD HH:mm:ss");
    }
  },
  ready() {
    this.flatpickr = new flatpickr(this.$el, Object.assign({}, this.config, {
      defaultDate: this.value,
      dateFormat: "Y-m-d H:i:S",
      static: true,
      onChange: (date) => {
        this.update(date);
      }
    }));
    window.f = this.flatpickr;
  },
});
