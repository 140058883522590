import Vue from "vue";
import topbar from "topbar";

import template from "templates/RequestForm.html";

export default Vue.extend({
  "props": {
    "action": {
      "required": true,
      "type": String
    },
    "method": {
      "type": String,
      "default": "GET",
      "validator": function(value) {
        return [
          "get",
          "put",
          "patch",
          "delete",
          "post"
        ].indexOf(value.toLowerCase()) > -1;
      }
    }
  },
  "data": function() {
    return {
      "formMethod": "GET",
      "formType": false
    };
  },
  "template": template,
  "created": function() {
    const method = this.method.toUpperCase();
    this.$set(
      "formMethod",
      method === "GET" ? "GET" : "POST"
    );
    this.$set(
      "formType",
      ["GET", "POST"].indexOf(method) === -1 ? method : false
    );
  },
  "methods": {
    "makeRequest": function() {
      const _do = () => {
        topbar.show();
        const formData = {};

        let inputs = this.$el.querySelectorAll("input", "textarea");
        inputs = [].slice.call(inputs, 0);

        inputs.forEach(function(field) {
          const name = field.getAttribute("name");
          if (!name) {
            return;
          }
          formData[name] = field.value;
        });

        this.$http({
          "followRedirect": false,
          "url": this.action,
          "method": this.formType ? this.formType : this.formMethod,
          "data": formData
        })
        .then(() => {

        })
        .catch(() => {

        });
      };
      _do();
    }
  }
});
