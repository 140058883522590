/**
 * Global Vue instance configuration
 */

import alert from "sweetalert2";
import bindLinks from "util/bindLinks";
import pjax from "util/pjax";



export default {
  "el": "body",
  "data": {
    "ajaxContent": "<p></p>",
    "menuOpen": false,
    "pageLoading": false,
    "showLog": false,
    "showRunningLog": false,
    "showLogPdf": false,
    "showOther": false,
    "showBail": false,
    "showArrangement": false,
    "showAdditional": false,
    "showFile": false,
    "showCheckout": false,
    "YpId":"",
    "showCheckin": false,
    "showBudgetCheckin": false,
    "showYpCheckout": false,
    "showFire": false,
    "showMaintenance": false,
    "showAppointmentCreateModal": false,
    "showForms": false,
    "showViewForms": false,
    "showBodyMap": false,
    "showBodyMapView": false,
    "showMed": false,
    "showFireDrillModal": false,
    "showRiskLog": false,
    "showRiskEntry": false,
    "showRiskLogPdf": false,
    "showVisitor": false,
    "showProfile1": true,
    "showProfile2": false,
    "showProfile3": false,
    "showPanel": false,
    "showPanel1": true,
    "showPanel2": false,
    "showPanel3": false,
    "showPanel4": false,
    "showPanel5": false,
    "showPanel6": false,
    "showTab1": false,
    "showTab2": false,
    "showTab3": false,
    "showTab4": false,
    "showTab5": false,
    "showTab6": false,
    "showTab7": false,
    "showTab8": false,
    "showTab9": false,
    "showTab10": false,
    "showTab11": false,
    "showTab12": false,
    "showTab13": false,
    "showTab14": false,
    "showTab15": false,
    "showTab16": false,
    "showTab17": false,
    "showTab18": false,
    "showTab19": false,
    "showTab20": false,
    "showTab21": false,
    "showTab22": false,
    "showTab23": false,
    "showTab24": false,
    "showTab25": false,
    "showTab26": false,
    "showTab27": false,
    "showTab28": false,
    "showTab29": false,
    "showTab30": false,
    "showTab31": false,
    "showTab32": false,
    "showTab33": false,
    "showTab34": false,
    "showTab37": false,
    "showTab38": false,
    "showTab39": false,
    "showTab40": false,
    "showTab41": false,
    "showTab42": false,
    "showTab43": false, /*dbs tab in staff profile */
    "showTab44": false, /*Locations without yp in monitor section */
    "showTab46": false, /* My History */
    "showTab47": false, /* What is my Aim? */
    "showTab48": false, /* My Health Needs? */
    "showMyHistory": false,
    "showWhatIsMyAim": false,
    "showMyHealthNeeds": false,
    "showTab45": false, /*case notes */
    "showDBS": false,
    "showDBSView" : false,
    "showDBSEdit" : false,
    "viewDBSId" : 0,
    "editDBSId" : 0,
    "DBSData": [],
    "eventName": "",
    "currDate": new Date(),
    "currTime": null,
    "datetime": null,
    "firedrill": false,
    "showPPS": false,
    "showYpLogPdf": false,
    "showPolicy": false,
    "showIncident": false,
    "showStaffCheckin": false,
    "showStaffCheckout": false,
    "myCalendar": false,
    "showDeleteButton": false,
    "buttonId": 0,
    "enableRelationship": false,
    "showStaffLocation": false,
    "showRiskLocation": false,
    "showCreateProfCompany": false,
    "showCreateProfessional": false,
    "showCreateMember": false,
    "showCreateStaff": false,
    "showcheckoutComment": false,
    "showcheckoutDetail": false,
    "checkedOutOn": "",
    "checkedOutBy": "",
    "checkedOutComment": "",
    "rolecheck": false,
    "showViewed": false,
    "showPending": false,
    "appointmentcheckoutComment": false,
    "appointmentCommentId": 0,
    "appointmentType":"",
    "showYP": false,
    "showMedicationInventory": false,
    "showMoneyInventory": false,
    "showValuableInventory": false,
    "showCheckinFilter": false,
    "showExtendedCheckin": false,
    "extendedCheckin": [],
    "extendedCheckinDate": null,
    "extendedCheckinWeek": null,
    "showCompanies": true,
    "showCheckinForm": false,
    "showAddOwner": false,
    "showMakesMeHappy": false,
    "showMakesMeUnhappy": false,
    "showViewsAndWishes": false,
    "showMyRelationships": false,
    "showKnowMyRisks": false,
    "showRiskReportOptions": false,
    "showContent": false,
    "staffSleep": false,
    "staffPresent": false,
    "showMyDay": true,
    "showMyDiary": true,
    "showYourTasks": true,
    "showYourClients": true,
    "showOnCall": false,
    "showOnCallEdit": false,
    "showStaffNotice":false,
    "showQualificationFile":false,
    "sleepcheck": "",
    "sleepmax": [],
    "rota_assignment_id": "",
    "showLocationhistory":false,
    "showDefaultPackage":false,
    "showcurrentCheckin":false,
    "showAddEntry":false,
    "showEmergencyContact":false,
    "showMainSubmitMsg":false,
    "showCheckoutOther": false,
    "showCheckoutSaffId": "",
    "showTab35": false, /* for Other staff currently checked-In monitor section */
    "showTab36": false, /* My Care in yp profile page */
    "showRotaAssignment":false,
    "rotaEndDay":0,
    "rotaTasksArray":[],
    "rotaStartTime":"",
    "rotaEndTime":"",
    "rotaID":"",
    "rotaDay":0,
    "statementIsTrue": "A",
    "showNewypComment":false,
    "showMovingYpComment":false,
    "showContactCheckin":false,
    "showContactCheckout":false,
    "showCheckoutconfirmed":false,
    "staffconfirmed":false,
    "showContactsCreateModal":false,
    "showSomethingBig":false,
    "showYPFamily":false,
    "showDairy":false,
    "showIncidentMissed":false,
    "smokeAlarmWorkingNo":false,
    "smokeAlarmValue": "",
    "showYPMissing":false,
    "ypMissingTalkYes":false,
    "ypMissingTalkNo":false,
    "showCommentMissedCallNotificationYes":false,
    "itemList": [],
    "roomCheck": [],
    "moreOffset":[],
    "roomCheckCounter":1,
    "defaultAimAreaId": "",
    "setDefaultprimaryAims": "",
    "squenceId": 0, /* set sequence id to show modal pop-up */
    "customAimId": 0, /* set custom aim id to show modal pop-up */
    "showSequenceAimInfo": false,
    "showCustomAimPop": false,
    "addOffsetCount":0,
    "addMoreOffsetCount":0,
    "addMoreSubOffsetsCount":0,
    "IncidentRptNeedCmplted": "-1",
    "measureData": {},
    "measureId": 0,
    "maintenanceWorkIsCompleted":0,
    "handledBy": "all",
    "ddAnyMoneyGiven": "",
    "ddMedication": "",
    "caseNoteId": 0,
    "showcaseNoteInfo": false,
    "OtherProfessional": "",
    "showDDWeeklyReportModal": false,
    "showStaffTrainingTab": false,
    "showCaseNoteForm":false,
    "showcaseNoteLogsInfo":false,
    "showCaseNotesInfo":false,
    "showTabTraining":false,
    "showOnCallNotificationModal":false,
    "showShiftCaluclationDate":false,
    "showSignedDocs":false,
    "showSignedDocsSignature":false,
    "showIpadForm":false,
    "showAgentIns":false,
    "showGasSafety":true,
    "showVirtualInspection":true,
    "showVirtualInspectionconfirmed":false,
    "showTabEET":false,
    "showTabMedical":false,
    "showEETCreateModal":false,
    "showMedicalCreateModal":false,
    "showMyHealth":false,
    "showMyEET":false,
    "showMyAccomodation":false,
    "showMySupport":false,
    "showMyGoal":false,
    "showMyContact":false,
    "showMyEmotional":false,
    "showTabMyHealth":false,
    "showTabMyEET":false,
    "showTabMyAccomodation":false,
    "showTabMySupport":false,
    "showTabMyGoal":false,
    "showTabMyContact":false,
    "showTabMyEmotional":false,
    "showRiskViewed":false,
    "showRiskPending":false,
    "showCreateRiskTemplate":false,
    "showTaboncallprotocal":false,
    "showoncallprotocal":false,
    "showoncallprotocalEdit":false,
    "showoncallprotocalView":false,
    "showProfile4":false,
    "updateFurtherProfileInfoForms":false,
    "showTabBusinessInsurance":false,
    "showBusinessInsurance":false,
    "showBusinessInsuranceForm":false,
    "showBusinessInsView" : false,
    "viewBusinessInsId" : 0,
    "showTabMOT":false,
    "showMot":false,
    "showMotForm":false,
    "showMotView" : false,
    "viewMotId" : 0,
    "showTabBusinessIns":false,
    "showTabMotmon":false,
    "addSupervisionUploads":false,
    "supervisionUploadsEdit":false,
    "supervisionUploadsobj": {"supervision_uploads_id": "", "issue_date": "", "files": "", "viewfile": ""},
    "showTabManagesupervision":false,
    "showSupervision":false,
    "showNotAssignSupervisee":false,
    "showViewSupervision":false,
    "oncallnotificationpdf":false,
    "showTabDisciplinary":false,
    "showTabDisciplinaryTab":false
  },
  "ready": function(){
    if(this.setSequenceAjx === "1"){
      this.$http.get("/person/get_sequence/ajax",
        function(response) {
          this.sequenceAims = response;
        });
    }
  },
  "methods": {
    "alert": function(title,text="",type="") {
      alert({
        title: title,
        text: text,
        type: type,
        currentProgressStep: 0
      });
    },
    "goBack": function() {
      window.history.back();
    },
    "confirm": function(title, text, form) {
      alert({
        title: title,
        text: text,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Yes",
        currentProgressStep: 0}
      ).then(() => {
        document.forms[form].submit();
      });
    },
    "changeCompany": function(event) {
      let parent = event.target.parentNode;
      while (parent && parent.tagName.toLowerCase() !== "form") {
        parent = parent.parentNode;
      }

      if (parent) {
        parent.submit();
      }
    },
    "changeOwnerCompany": function(event) {
      let parent = event.target.parentNode;
      while (parent && parent.tagName.toLowerCase() !== "form") {
        parent = parent.parentNode;
      }

      if (parent) {
        parent.submit();
      }
    },
    "showUserArea": function(event) {
      let parent = event.target.parentNode;
      while (parent && parent.tagName.toLowerCase() !== "form") {
        parent = parent.parentNode;
      }

      if (parent) {
        parent.submit();
      }
    },
    "notificationSendType": function(event) {
      if (event.target.checked === true &&
            event.target.value === "company_wide") {
        document.getElementsByClassName("notification__areas")[0]
          .style.display = "none";
      } else if (event.target.checked === true &&
            event.target.value === "area_wide") {
        document.getElementsByClassName("notification__areas")[0]
          .style.display = "block";
      }
    },
    "notificationTypeChange": function(event) {
      if (event.target.innerText === "On-Call") {
        document.getElementsByClassName("notification__youngpersons")[0]
          .style.display = "block";
      } else {
        document.getElementsByClassName("notification__youngpersons")[0]
          .style.display = "none";
      }
    },
    "notificationYoungPersonChange": function(event) {
      if (event.target.innerText === "Select Young Person") {
        document
        .getElementsByClassName("notification__youngpersons--bailcondition")[0]
        .innerHTML = "";
      } else {
        const personId = document.getElementsByName("young_person_id")[0].value;
        this.$http.get("/person/bailcondition/" + personId + "/ajax/get",
          function(content) {
            if (content[0].status === "success") {
              document
                .getElementsByClassName("notification__youngpersons--bailcondition")[0]
                .innerHTML = "<p><b>Bail Condition: </b>" + content[0]
                .information + "</p>";
            } else {
              document
                .getElementsByClassName("notification__youngpersons--bailcondition")[0]
                .innerHTML = "";
            }
          });
      }
    },
    "notificationViewedUsers": function(event) {
      this.$http.get("/notification/viewed/" +
        event.target.getAttribute("data-id") + "/ajax/get",
        function(content) {
          const element = document
                        .getElementsByClassName("notificationViewedUsers")[0];
          element.innerHTML = "";
          if (content[0].status === "success") {
            for (let i = 0; i < content.length; i++) {
              element.innerHTML += "<p>" +
                content[i].name + "</p>";
            }
          } else {
            element.innerHTML = "<p>No Users</p>";
          }
          this.showViewed = true;
        });
    },
    "notificationPendingUsers": function(event) {
      this.$http.get("/notification/pending/" +
        event.target.getAttribute("data-id") + "/ajax/get",
        function(content) {
          const element = document
                          .getElementsByClassName("notificationPendingUsers")[0];
          element.innerHTML = "";
          if (content[0].status === "success") {
            for (let i = 0; i < content.length; i++) {
              element.innerHTML += "<p>" +
                content[i].name + "</p>";
            }
          } else {
            element.innerHTML = "<p>No Users</p>";
          }
          this.showPending = true;
        });
    },
    "policyAndProcedureViewedUsers": function(event) {
      this.$http.get("/policy/viewed/" +
        event.target.getAttribute("data-id") + "/ajax/get",
        function(content) {
          const element = document
                        .getElementsByClassName("policyViewedUsers")[0];
          element.innerHTML = "";
          if (content[0].status === "success") {
            for (let i = 0; i < content.length; i++) {
              if(content[i].sign){
                element.innerHTML += "<p>";
                element.innerHTML += "<a href='staff/"+content[i].id+"'>" +
                content[i].name + "</a>";
                element.innerHTML +=
                "<img height='100px' width='200px' src='"+content[i].sign+"'></img>";
                element.innerHTML += "<span>"+content[i].date+"</span></p>";
              }else{
                element.innerHTML += "<p>";
                element.innerHTML += "<a>" +
                content[i].name + "</a></p>";
              }
            }
          } else {
            element.innerHTML = "<p>No Users</p>";
          }
          this.showViewed = true;
        });
    },
    "policyAndProcedurePendingUsers": function(event) {
      this.$http.get("/policy/pending/" +
        event.target.getAttribute("data-id") + "/ajax/get",
        function(content) {
          const element = document
                          .getElementsByClassName("policyPendingUsers")[0];
          element.innerHTML = "";
          if (content[0].status === "success") {
            for (let i = 0; i < content.length; i++) {
              element.innerHTML += "<p>" +
                content[i].name + "</p>";
            }
          } else {
            element.innerHTML = "<p>No Users</p>";
          }
          this.showPending = true;
        });
    },
    "notificationShowFullInfo": function(event) {
      const element = event.target;
      const elementExcerpt = event.target.parentNode.parentNode
                      .getElementsByClassName("notification__excerpt")[0];
      const elementFullInfo = event.target.parentNode.parentNode
                      .getElementsByClassName("notification__fullinfo")[0];

      if (element.innerText === "View More") {
        elementExcerpt.style.display = "none";
        elementFullInfo.style.display = "block";
        element.innerText = "View Less";
      } else {
        elementExcerpt.style.display = "block";
        elementFullInfo.style.display = "none";
        element.innerText = "View More";
      }
    },
    "showOncallNotification": function(event) {
      const oncall_id = event.target.getAttribute("data-id");
      const information = event.target.getAttribute("data-text");
      const info = event.target.getAttribute("data-info");
      if (event.target.innerText === "Edit") {
        document.getElementById("editoncall_id").value = oncall_id;
        //document.getElementById("information").value = information;
        document.getElementById("informationedittextarea").value = information;
        document.getElementById("informationedittextarea").innerText = information;
        document.getElementById("informationedittextarea").innerHTML = information;
        document.getElementById("informationeditdiv").innerHTML = information;
        document.getElementsByClassName("oncallprotocal_information")[0].innerHTML = info;
        this.showOnCallEdit = true;
      } else if(event.target.innerText === "Create") {
        document.getElementById("oncall_id").value = oncall_id;
        document.getElementById("oncall_id").innerText = oncall_id;
        document.getElementsByClassName("information")[0].innerHTML = information;
        document.getElementById("on_call_protocol").value = information;
        this.showOnCall = true;
      }
    },
    "logOncallNotification": function(event) {
      const information = event.target.getAttribute("data-text");
      const oncall_id = event.target.getAttribute("data-id");
      const info = event.target.getAttribute("data-info");
      const type = event.target.getAttribute("data-type");
      document.getElementById("comments").innerHTML = "Information:";
      document.getElementsByClassName("oncallprotocal_information")[0].innerHTML = "";
      document.getElementsByClassName("comment_label")[0].innerHTML = "";
      document.getElementsByClassName("commented_user")[0].innerHTML = "";
      if(type === "Comments") {
        document.getElementById("comments").innerHTML = "Manager Comment:";
        document.getElementById("information1").innerText = information;
        document.getElementById("information1").innerHTML = information;
        document.getElementById("information1").value = information;
        //document.getElementsByClassName("information")[0].innerHTML = information;
        document.getElementsByClassName("comment_label")[0].innerHTML = "Commented By:";
        document.getElementsByClassName("commented_user")[0].innerHTML = event.target.getAttribute("data-user");
        this.showOnCall=true;
      } else if(type === "View") {
       /* document.getElementsByClassName("information")[0].innerHTML = information;*/
        
        document.getElementById("information1textarea").innerText = information;
        document.getElementById("information1textarea").innerHTML = information;
        document.getElementById("information1textarea").value = information;
        document.getElementById("information1div").innerHTML = information;
        document.getElementsByClassName("oncallprotocal_information")[0].innerHTML = info;
        this.showOnCall=true;
      }else if(type === "ViewOnprotocal") {
       /* document.getElementsByClassName("information")[0].innerHTML = information;*/
        
        document.getElementById("oncallprotocalviewtextarea").innerText = information;
        document.getElementById("oncallprotocalviewtextarea").innerHTML = information;
        document.getElementById("oncallprotocalviewtextarea").value = information;
        document.getElementById("oncallprotocalviewdiv").innerHTML = information;
        document.getElementsByClassName("oncallprotocal_information")[0].innerHTML = info;
        this.showoncallprotocalView=true;
      } else if(type === "Edit") {
        document.getElementById("informationedittextarea").innerText = information;
        document.getElementById("informationedittextarea").innerHTML = information;
        document.getElementById("informationedittextarea").value = information;
        document.getElementById("informationeditdiv").innerHTML = information;
        document.getElementById("editoncall_id").value = oncall_id;
        document.getElementsByClassName("oncallprotocal_information")[1].innerHTML = info;
        this.showOnCallEdit=true;
      }
    },
    "showStaffNotices": function(event) {
      const staff_notice_information = event.target.getAttribute("data-text");
      const staff_notice_id = event.target.getAttribute("data-id");
      document.getElementsByClassName("staff_notice_information")[0]
        .innerHTML = staff_notice_information;
      document.getElementById("staff_notice_id").value = staff_notice_id;
      this.showStaffNotice=true;
    },
    "panelEntryShowFullInfo": function(event) {
      const element = event.target;
      const elementExcerpt = event.target.parentNode
                      .getElementsByClassName("panel__excerpt")[0];
      const elementFullInfo = event.target.parentNode
                      .getElementsByClassName("panel__fullinfo")[0];

      if (element.innerText === "View More") {
        elementExcerpt.style.display = "none";
        elementFullInfo.style.display = "inline";
        element.innerText = "View Less";
      } else {
        elementExcerpt.style.display = "inline";
        elementFullInfo.style.display = "none";
        element.innerText = "View More";
      }
    },
    "changeMaintenanceWorkLevel": function(event) {
      const currentDueDate = new Date(document
        .getElementsByName("currentMaintenanceDueDate")[0].value);
      let dueDate = new Date(Date("Y-m-d"));
      if (event.target.value === "Hazardous") {
        dueDate.setDate(dueDate.getDate() + 2);
      } else if (event.target.value === "Functional") {
        dueDate.setDate(dueDate.getDate() + 6);
      } else if (event.target.value === "Wear and Tear") {
        dueDate.setDate(dueDate.getDate() + 28);
      } else if (event.target.value === "Improvements") {
        dueDate.setDate(dueDate.getDate() + 30);
      } else {
        dueDate = currentDueDate;
      }
      const dueDateFormated = dueDate.getDate() + "/" + (dueDate.getMonth()+1) + "/"
        + dueDate.getFullYear();
      document.getElementsByName("due_date")[0].value = dueDateFormated;
      document.getElementsByClassName("due_date")[0].value = dueDateFormated;
    },
    "markMaintenanceComplete": function(event) {
      if (event.target.checked === true) {
        document.getElementsByClassName("maintenanceWorkComplete")[0]
          .style.display = "block";
        document.getElementsByName("image_completed")[0]
          .setAttribute("required","required");
        document.getElementsByName("cost")[0]
          .setAttribute("required","required");
        document.getElementsByName("cost_breakdown")[0]
          .setAttribute("required","required");
      } else {
        document.getElementsByClassName("maintenanceWorkComplete")[0]
          .style.display = "none";
        document.getElementsByName("image_completed")[0]
          .removeAttribute("required");
        document.getElementsByName("cost")[0]
          .removeAttribute("required");
        document.getElementsByName("cost_breakdown")[0]
          .removeAttribute("required");
      }
    },
    "changeMaintenanceWorkComments": function(event) {
      if (event.target.value !== null && event.target.value.trim() !== "") {
        if (document.getElementsByName("status")[0].value === "New") {
          document.getElementsByName("status")[0].value = "Actioned";
        }
      } else {
        document.getElementsByName("status")[0].value = "New";
      }
    },
    "markMaintenanceIncomplete": function(event) {
      if (event.target.checked) {
        document.getElementsByClassName("maintenanceWorkIncomplete")[0]
          .style.display = "block";
      } else {
        document.getElementsByClassName("maintenanceWorkIncomplete")[0]
          .style.display = "none";
      }
    },
    "addOwner": function(event) {
      event.preventDefault();
      const formData = new FormData(document.getElementsByName("addOwnerForm")[0]);
      this.$http.post("/admin/owner/ajax/store", formData, function(response) {
        if (response.status === "success") {
          const ownersList = document.getElementsByClassName("owners-list")[0]
            .innerHTML;
          document
            .getElementsByClassName("owners-list")[0]
            .innerHTML = "<option value='" + response.id + "'>" +
              response.name + "</option>" + ownersList;
          this.showAddOwner = false;
        } else if (response.status === "error") {
          document
            .getElementsByClassName("errorMessages")[0]
            .innerHTML = "<p class='error'>" + response.message + "</p>";
        }
      });
    },
    "ajaxPagination": function(event) {
      event.preventDefault();
      let url = event.target.getAttribute("href");
      url = url.replace("/?", "?");
      const contentBodyClass = event.target.parentNode.parentNode.parentNode
        .parentNode.className;
      console.log(contentBodyClass);
      this.$http.get(url, function(content) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, "text/html");
        document.getElementsByClassName(contentBodyClass)[0].innerHTML =
          doc.getElementsByClassName(contentBodyClass)[0].innerHTML;
        this.$compile(document);
      });
      window.history.pushState("", "", url);
    },
    "prefillRiskHistoryData": function(event) {
      event.preventDefault();
      const url = event.target.getAttribute("href");
      this.$http.get(url, function(content) {
        for(let i=0; i<content.length; i++) {
          document.querySelectorAll("input[name$='[score]']")[i]
            .value = content[i].score;
          const scoreItems = document.querySelectorAll("input[name$='[score]']")[i]
            .parentNode.getElementsByTagName("li");
          const risk_name = document.querySelectorAll("input[name$='[riskname]']")[i].value;
          for(let k=0; k<content.length; k++) {
            if(content[k].template_name === risk_name) {
              for (let j=0; j<scoreItems.length; j++) {
                scoreItems[j].className = "dropdown__list__item";
                if (scoreItems[j].innerHTML.toString().trim() === content[i].score.toString()) {
                  scoreItems[j].className += " dropdown__list__item--selected";
                }
              }
              document.querySelectorAll("textarea[name$='[description]']")[i]
                .value = content[i].description;
              document.querySelectorAll("textarea[name$='[strategy]']")[i]
                .value = content[i].strategy;
            }
          }
        }
      });
    },
    "showAimFeedbacks": function(aimId,measureId) {
      const url = "/person/individual-formajx/"+aimId+"/"+measureId;
      this.$http.get(url, function(content) {
        this.measureData = content;
      });
    },
    "showCompanyAim": function(event) {
      const companyAimRatio = event.target.options[event.target.selectedIndex]
        .getAttribute("data-aim-ratio").toString();
      document.getElementsByName("aims_per_shift")[0].value = companyAimRatio;
    },
    "showCompanyShiftDiscrepancy": function(event) {
      const companyShiftDiscrepancy = event.target.options[event.target.selectedIndex]
        .getAttribute("data-shift-discrepancy").toString();
      document.getElementsByName("shift_discrepancy")[0].value = companyShiftDiscrepancy;
    },
    "checkAllowedFileTypes": function(event) {
      event.preventDefault();
      const element = event.target;
      const fileInput = document.getElementsByClassName("file")[0];
      if (fileInput && fileInput.value) {
        let extension = fileInput.value.split(".");
        if(extension && extension.length > 1){
          extension = extension[extension.length-1].toUpperCase();
          if (["PNG","JPG","JPEG"].indexOf(extension) !== -1 && element) {
            this.showMainSubmitMsg = true;
            element.submit();
          } else{
            document.getElementsByClassName("file")[0].value = "";
            document.getElementsByClassName("fileTypeError")[0]
              .innerText = "*Only images are allowed.";
            this.showMainSubmitMsg = false;
          }
        }
      } else {
        if (element) {
          element.submit();
        }
      }
    },
    getCurDate: function() {
      const today = new Date();
      this.currDate = today;
    },
    openEvent: function() {
      this.showContent = true;
    },
    closeEvent: function() {
      this.showContent = false;
    },
    hideEvent: function() {
      this.showPanel = false;
      this.showOther = false;
    },
    changeEvent: function(evt) {
      this.showOther = false;
      this.showPanel = true;
      this.eventName = evt;
      const today = new Date();
      this.currDate = today;
      const h = today.getHours();
      let m = today.getMinutes();
      m = this.checkTime(m);
      this.currTime = h + ":" + m;
      this.datetime = this.currDate.getFullYear() + "-" +
        (this.currDate.getMonth() + 1) + "-" +
        this.currDate.getDate();
    },
    OtherForm: function(evt, id) {
      this.showPanel = false;
      this.showOther = true;
      this.eventName = evt;
      this.buttonId = id;
      const today = new Date();
      this.currDate = today;
      const h = today.getHours();
      let m = today.getMinutes();
      m = this.checkTime(m);
      this.currTime = h + ":" + m;
      this.datetime = this.currDate.getFullYear() + "-" +
        (this.currDate.getMonth() + 1) + "-" +
        this.currDate.getDate();
    },
    checkTime: function(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
    changeYP: function(evt) {
      const panel = evt;
      const panelname = "showPanel" + panel;

      if (this[panelname] === true) { this[panelname] = false; }
      else { this[panelname] = true; }
    },
    changePanelTabs: function(evt) {
      const tab = evt;
      const tabname = "showTab" + tab;
      if (this[tabname] === true) { this[tabname] = false; this.handledBy = "all"; }
      else { this[tabname] = true; }
    },
    changeYPProfile: function(evt) {
      const profile = evt;
      const profilename = "showProfile" + profile;

      this.showProfile1 = false;
      this.showProfile2 = false;
      this.showProfile3 = false;
      this.showProfile4 = false;
      this[profilename] = true;
    },
    RiskEntry: function(url) {
      this.$http.get(url, function(content) {
        document.getElementById("risk_entry_content").innerHTML = content;
        this.showRiskEntry = true;
      });
    },
    AddTask: function(id) {
      let curCount = 0;
      if (document.getElementById("add_task_count_" + id).value !== "") {
        curCount = parseInt(document.getElementById(
          "add_task_count_" + id).value
        );
      }
      document.getElementById("add_task_count_" + id).value = curCount + 1;
      let html = "<li>\n <input type='text' name='risk[" +
        id + "][tasks_new][" + (
        curCount + 1) + "][task_name]' placeholder='Task name'>\n " +
        "<textarea name='risk[" + id + "][tasks_new][" + (
        curCount + 1) + "][task_resource]' " +
        "placeholder='Resource Link/Paragraph'></textarea>\n <ol>";

      for (let i = 1; i < 5; i++) {
        html += "<li>\n <input type='text' name='risk[" + id + "][tasks_new][" +
        (curCount + 1) + "][question_" + i +
        "][question]' placeholder='Question'>\n <ol>";
        for (let j = 1; j < 5; j++) {
          html += "<li>\n <input type='text' name='risk[" + id +
          "][tasks_new][" + (curCount + 1) + "][question_" + i +
          "][answer_" + j + "]'' value='' " +
          "placeholder='Possible Answer " + j +
          "'>\n <label>\n <input type='radio' " +
          "name='risk[" + id + "][tasks_new][" + (curCount + 1) +
          "][question_" + i +
          "][is_answer]' value='" + j + "' > Answer? \n</label>\n</li>\n";
        }
        html += "\n</ol>\n</li>\n";
      }
      html += "\n</ol>\n</li>\n";
      const ol = document.getElementById("ol_" + id);
      ol.insertAdjacentHTML("beforeend", html);
    },
    AddQuestion: function(id) {
      let curCount = 0;
      if (document.getElementById("add_task_question_count_" +
        id).value !== "") {
        curCount = parseInt(document.getElementById("add_task_question_count_" +
        id).value);
      }
      document.getElementById("add_task_question_count_" +
        id).value = curCount + 1;
      let html = "<li>\n <input type='text' name='risk[tasks][" + id +
      "][question_new][" + (curCount + 1) +
      "][question]' value='' placeholder='Question'>\n <ol>";
      for (let i = 1; i < 5; i++) {
        html += "<li>\n <input type='text' name='risk[tasks][" + id +
        "][question_new][" + (curCount + 1) + "][answer_" + i +
        "]' value='' placeholder='Possible Answer " + i +
        "'>\n <label>\n <input type='radio' name='risk[tasks][" + id +
        "][question_new][" + (curCount + 1) + "][is_answer]' value='" + i +
        "'> Answer? \n</label>\n</li>\n";
      }
      html += "\n</ol>\n</li>\n";
      const ol = document.getElementById("olq_" + id);
      ol.insertAdjacentHTML("beforeend", html);
    },
    AddTpl: function(id) {
      let curCount = 0;
      if (document.getElementById("add_tpl_count_" + id).value !== "") {
        curCount = parseInt(document.getElementById("add_tpl_count_" +
          id).value);
      }
      document.getElementById("add_tpl_count_" + id).value = curCount + 1;
      let html = "<li>\n <input class='new' placeholder='Add new aim' " +
      "type='text' name='section[" + id +
      "][template_new][" + (curCount + 1) +
      "][template_name]' value=''>\n <textarea name='section[" + id +
      "][template_new][" + (curCount + 1) +
      "][template_contents]' value='' placeholder='New aim description'>" +
      "</textarea>";
      html += "\n</li>\n";
      const ol = document.getElementById("oltpl_" + id);
      ol.insertAdjacentHTML("beforeend", html);
    },
    AddAimTpl: function(id) {
      let curCount = 0;
      if (document.getElementById("add_tpl_count_" + id).value !== "") {
        curCount = parseInt(document.getElementById("add_tpl_count_" +
          id).value);
      }
      document.getElementById("add_tpl_count_" + id).value = curCount + 1;
      let html = "<li>\n <input class='new' placeholder='Add New Aim' " +
      "type='text' name='section[" + id +
      "][template_new][" + (curCount + 1) +
      "][template_name]' value=''>\n <textarea name='section[" + id +
      "][template_new][" + (curCount + 1) +
      "][template_guidance]' value='' placeholder='New Aim Guidance'>" +
      "</textarea>" +
      "<ul class='aim-measures-list'><li>Aim Measures</li><li><ol>" +
      "<li><input type='tex' name='section[" + id +
      "][template_new][" + (curCount + 1) +
      "][measure_1_name]' required placeholder='Name' value='Score - 1' readonly>" +
      "<textarea name='section[" + id +
      "][template_new][" + (curCount + 1) +
      "][measure_1_description]' placeholder='Description'></textarea>" +
      "<textarea name='section[" + id +
      "][template_new][" + (curCount + 1) +
      "][measure_1_improvements]' placeholder='Improvements'></textarea>" +
      "</li><li><input type='text' name='section[" + id +
      "][template_new][" + (curCount + 1) +
      "][measure_2_name]' required placeholder='Name' value='Score - 2' readonly>" +
      "<textarea name='section[" + id +
      "][template_new][" + (curCount + 1) +
      "][measure_2_description]' placeholder='Description'></textarea>" +
      "<textarea name='section[" + id +
      "][template_new][" + (curCount + 1) +
      "][measure_2_improvements]' placeholder='Improvements'></textarea>" +
      "</li><li><input type='text' name='section[" + id +
      "][template_new][" + (curCount + 1) +
      "][measure_3_name]' required placeholder='Name' value='Score - 3' readonly>" +
      "<textarea name='section[" + id +
      "][template_new][" + (curCount + 1) +
      "][measure_3_description]' placeholder='Description'></textarea>" +
      "<textarea name='section[" + id +
      "][template_new][" + (curCount + 1) +
      "][measure_3_improvements]' placeholder='Improvements'></textarea>" +
      "</li><li><input type='text' name='section[" + id +
      "][template_new][" + (curCount + 1) +
      "][measure_4_name]' required placeholder='Name' value='Score - 4' readonly>" +
      "<textarea name='section[" + id +
      "][template_new][" + (curCount + 1) +
      "][measure_4_description]' placeholder='Description'></textarea>" +
      "<textarea name='section[" + id +
      "][template_new][" + (curCount + 1) +
      "][measure_4_improvements]' placeholder='Improvements'></textarea>" +
      "</li><li><input type='text' name='section[" + id +
      "][template_new][" + (curCount + 1) +
      "][measure_5_name]' required placeholder='Name' value='Score - 5' readonly>" +
      "<textarea name='section[" + id +
      "][template_new][" + (curCount + 1) +
      "][measure_5_description]' placeholder='Description'></textarea>" +
      "<textarea name='section[" + id +
      "][template_new][" + (curCount + 1) +
      "][measure_5_improvements]' placeholder='Improvements'></textarea>" +
      "<li><input type='tex' name='section[" + id +
      "][template_new][" + (curCount + 1) +
      "][measure_6_name]' required placeholder='Name' value='Score - 6' readonly>" +
      "<textarea name='section[" + id +
      "][template_new][" + (curCount + 1) +
      "][measure_6_description]' placeholder='Description'></textarea>" +
      "<textarea name='section[" + id +
      "][template_new][" + (curCount + 1) +
      "][measure_6_improvements]' placeholder='Improvements'></textarea>" +
      "</li><li><input type='text' name='section[" + id +
      "][template_new][" + (curCount + 1) +
      "][measure_7_name]' required placeholder='Name' value='Score - 7' readonly>" +
      "<textarea name='section[" + id +
      "][template_new][" + (curCount + 1) +
      "][measure_7_description]' placeholder='Description'></textarea>" +
      "<textarea name='section[" + id +
      "][template_new][" + (curCount + 1) +
      "][measure_7_improvements]' placeholder='Improvements'></textarea>" +
      "</li><li><input type='text' name='section[" + id +
      "][template_new][" + (curCount + 1) +
      "][measure_8_name]' required placeholder='Name' value='Score - 8' readonly>" +
      "<textarea name='section[" + id +
      "][template_new][" + (curCount + 1) +
      "][measure_8_description]' placeholder='Description'></textarea>" +
      "<textarea name='section[" + id +
      "][template_new][" + (curCount + 1) +
      "][measure_8_improvements]' placeholder='Improvements'></textarea>" +
      "</li><li><input type='text' name='section[" + id +
      "][template_new][" + (curCount + 1) +
      "][measure_9_name]' required placeholder='Name' value='Score - 9' readonly>" +
      "<textarea name='section[" + id +
      "][template_new][" + (curCount + 1) +
      "][measure_9_description]' placeholder='Description'></textarea>" +
      "<textarea name='section[" + id +
      "][template_new][" + (curCount + 1) +
      "][measure_9_improvements]' placeholder='Improvements'></textarea>" +
      "</li><li><input type='text' name='section[" + id +
      "][template_new][" + (curCount + 1) +
      "][measure_10_name]' required placeholder='Name' value='Score - 10' readonly>" +
      "<textarea name='section[" + id +
      "][template_new][" + (curCount + 1) +
      "][measure_10_description]' placeholder='Description'></textarea>" +
      "<textarea name='section[" + id +
      "][template_new][" + (curCount + 1) +
      "][measure_10_improvements]' placeholder='Improvements'></textarea>" +
      "</li></ol></li></ul>";
      html += "\n</li>\n";
      const ol = document.getElementById("oltpl_" + id);
      ol.insertAdjacentHTML("beforeend", html);
    },
    FireDrill: function(url) {
      this.$http.get(url, function(data) {
        if (data.firedrill > 0) {
          this.firedrill = true;
        } else {
          this.firedrill = false;
        }
      });
    },
    getAimTemplates: function(url, el, sel) {
      this.$http.get(url + "?location=" +
      document.getElementById(el).value, function(data) {
        const dropdown = document.getElementById(sel);
        dropdown.innerHTML = "";
        const option = document.createElement("option");
        option.text = "None";
        option.setAttribute("value", "");
        dropdown.add(option);
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            const optgroup = document.createElement("optgroup");
            optgroup.setAttribute("label", data[i].section);
            for (let j = 0; j < data[i].template.length; j++) {
              const childoption = document.createElement("option");
              childoption.text = data[i].template[j].name;
              childoption.setAttribute("value", data[i].template[j].id);
              optgroup.appendChild(childoption);
            }
            dropdown.add(optgroup);
          }
        }
      });
    },
    showRelationship: function(el) {
      if (document.getElementById(el).value === "FA") {
        this.enableRelationship = true;
        document.getElementsByName("relationship")[0].setAttribute("required","required");
      }else {
        this.enableRelationship = false;
        document.getElementsByName("relationship")[0].removeAttribute("required");
      }
    },
    AddMember: function() {
      const person = document.getElementById("person_id").value;
      if (person !== "") {
        this.showCreateMember = true;
        document.getElementById("visitor_member_form").action = "/person/" +
        person + "/family/save";
      }else {
        this.alert("","Please choose location and person to add member","error");
        this.showCreateMember = false;
      }
    },
    showcheckoutBox: function(url) {
      this.showcheckoutComment = true;
      document.getElementById("visitor_checkout_form").action = url;
    },
    showFiredrillcheckoutBox: function(url) {
      this.showcheckoutComment = true;
      document.getElementById("firedrill_visitor_checkout_form").action = url;
    },
    showcheckoutContent: function(user, checkout, comment) {
      this.checkedOutComment = comment;
      this.checkedOutBy = user;
      this.checkedOutOn = checkout;
      this.showcheckoutDetail = true;
    },
    roleChecked: function(e) {
      const roleValue = e.target.options[e.target.selectedIndex].innerHTML;
      if (roleValue === "Off-Site Specialist") {
        this.rolecheck = true;
      } else {
        this.rolecheck = false;
      }
    },

    locationPdf: function(url) {
      const formId = document.getElementById("pdf_form");
      const formelement = formId.elements[0].value;
      this.$http({
        url: url,
        method: "GET",
        data: {
          dateinput: formelement
        }
      }).then(function success(response) {
        if (response.data === "ok") {
          formId.target = "_blank";
        } else {
          formId.target = "_self";
        }
        formId.submit();
      });
    },
    changeMaintenanceProfesstional: function() {
      if (document.getElementsByName("professional_id")[0].value > 0) {
        document.getElementsByClassName("maintenanceStaffs")[0]
          .style.display = "none";
        document.getElementsByName("staff_id")[0].value = "";
      } else {
        document.getElementsByClassName("maintenanceStaffs")[0]
          .style.display = "block";
      }
    },
    changeMaintenanceStaff: function() {
      if (document.getElementsByName("staff_id")[0].value > 0) {
        document.getElementsByClassName("maintenanceProfessionals")[0]
          .style.display = "none";
        document.getElementsByName("professional_id")[0].value = "";
      } else {
        document.getElementsByClassName("maintenanceProfessionals")[0]
          .style.display = "block";
      }
    },
    getExtendedCheckins: function(checkins, week, date) {
      this.showExtendedCheckin = true;
      this.extendedCheckin = checkins;
      this.extendedCheckinDate = date;
      this.extendedCheckinWeek = week;
      console.log(checkins);
    },
    "showSleepHours": function() {
      if (document.getElementsByName("sleep")[0].value === "1") {
        this.staffSleep = true;
      } else {
        this.staffSleep = false;
      }
    },
    "showSmokeAlarmChecked": function() {
      if (document.getElementsByName("staff_present")[0].value === "1") {
        this.staffPresent = true;
      } else {
        this.staffPresent = false;
      }
    },
    "ready": function() {
      window.pjax = pjax;
      pjax.parseDOM(document);
      bindLinks();
      setInterval(() => {
        this.FireDrill("/dashboard/firedrill/ajax/get");
      }, 30000);
    },
    "setYp":function(person){
      this.showCheckout = true;
      const details = person.split(",");
      this.YpId = details[0];
      if(details[1] === "1") {
        this.sleepcheck = true;
      }else{
        this.sleepcheck = false;
      }
      for (let i = 0; i <= details[2]; i++) {
        this.sleepmax[i] = i;
      }
      this.rota_assignment_id = details[3];
    },
    "setYpId":function(personid){
      this.showCheckout = true;
      this.YpId = personid;
    },
    "incidentReported": function(event) {
      if (event.target.checked === true && event.target.value === "1") {
        document.getElementsByClassName("incident_reported")[0]
          .style.display = "block";
      } else {
        document.getElementsByClassName("incident_reported")[0]
          .style.display = "none";
      }
    },
    "primary_concern_other": function(event) {
      let conStyle = "none";
      for(let i=0; i < event.target.options.length; i++) {
        if (event.target.options[i].selected && event.target.options[i].value === "Other") {
          conStyle = "block";
        }
      }
      document.getElementsByClassName("primary_concern_other")[0]
        .style.display = conStyle;
    },
    bodyMapView: function(event) {
      document.querySelector("#body-map-view-severity").innerText
        =event.target.getAttribute("severity");
      document.querySelector("#body-map-view-details").innerText
        =event.target.getAttribute("details");

      if(event.target.getAttribute("image")!==""){
        document.querySelector("#body-map-view-bodymap_img")
          .setAttribute("src","data:application/image;base64,"+event.target.getAttribute("image"));
      }
      else{
        document.querySelector("#body-map-view-bodymap_img")
          .setAttribute("src","/images/default_image.gif");
      }
      this.showBodyMapView=true;
    },
    "prefillRiskHistoryDataForSelect": function(event) {
      const url = event.target.getAttribute("href");
      this.$http.get(url, function(content) {
        for(const item in content) {
          const score_id = "score_"+content[item].template_id;
          const strategy_id = "strategy_"+content[item].template_id;
          const description_id = "description_"+content[item].template_id;
          if(document.getElementById(score_id))
            document.getElementById(score_id).value = content[item].score;

          if(document.getElementById(strategy_id)){
            const strategyDivTag = document.getElementById(strategy_id).getElementsByTagName("DIV")[1];
            const strategyTextareaTag = document.getElementById(strategy_id).getElementsByTagName("TEXTAREA")[0];
            
            //document.getElementById(score_id).value = content[item].score;
            strategyDivTag.innerHTML = content[item].strategy;
            strategyTextareaTag.value = content[item].strategy;
            //document.getElementById(strategy_id).value = content[item].strategy;
          }

          if(document.getElementById(description_id)){
            const descriptionDivTag = document.getElementById(description_id).getElementsByTagName("DIV")[1];
            const descriptionTextareaTag = document.getElementById(description_id).getElementsByTagName("TEXTAREA")[0];
            
            descriptionDivTag.innerHTML = content[item].description;
            descriptionTextareaTag.value = content[item].description;
          }
        }
      });
    },
    showOtherCheckout: function(staff_id) {
      this.showCheckoutSaffId = staff_id;
      this.showCheckoutOther = true;
      this.showcurrentCheckin = false;
      this.staffSleep = false;
    },
    showNewyp: function(newrecord,movingrecord="") {
      if(movingrecord){
        console.log(newrecord+"   "+movingrecord);
        document.getElementById("oldrecords_id").value = movingrecord;
        document.getElementById("newrecords_id").value = newrecord;
        this.showMovingYpComment=true;
      }else{
        document.getElementById("newrecord_id").value = newrecord;
        this.showNewypComment=true;
      }
    },
    showStaffCheckoutConfirmation: function() {
      this.showCheckoutconfirmed = true;
    },
    sendStaffCheckoutConfirmationMail: function(id){
      const $this = this;
      console.log(id);
      console.log(id);
      $this.$http.get("overtime",{"shift":id},
       function(response) {
         if(response===1){
           document.getElementById("confirm-msg").classList.remove("hide");
           document.getElementById("confirm-msg").classList.remove("show");
         }
         console.log(response);
         $this.$compile(document);
       });
    },
    filterTrigers: function(id,url,classname){
      const $this = this;
      console.log(id);
      const filter_log = document.getElementById(id).value;
      this.randomvalue = id;
      console.log(this.randomvalue);
      $this.$http.get(url,
        { [$this.randomvalue] :filter_log},
            function(response){
              if(response.length > 0) {
                console.log(response);
                document.getElementsByClassName(classname)[0].innerHTML = response;
                $this.$compile(document);
              }
            });
    },
    showYPFamilyStatus :function(area,status,areaid){
      const $this = this;
      $this.$http.get("yp",{"status":status,"area":areaid},
       function(response) {
         this.showYPFamily = true;
         document.getElementById("family_id").innerHTML = response;
         document.getElementById("status").innerHTML = status;
         document.getElementById("family_area").innerHTML = area;
         $this.$compile(document);
       });
    },
    showStaffCheckoutConfirmed :function(id){
      document.getElementById("staffconfirmed_id").value = id;
      this.showCheckoutconfirmed = true;
    },
    logTrigers: function(id,url,classname){
      const $this = this;
      console.log(id);
      const filter_log = document.getElementById(id).value;
      this.randomvalue = id;
      console.log(this.randomvalue);
      $this.$http.get(url,
        { [$this.randomvalue] :filter_log},
            function(response){
              if(response.length > 0) {
                console.log(response);
                document.getElementsByClassName(classname)[0].innerHTML = response;
                $this.$compile(document);
              }
            });
    },
    showMissed: function(type,id) {
      console.log(type+" "+id);
      document.getElementById("missed_id").value = id;
      if(type==="incident"){
        this.showIncidentMissed=true;
      }
      if(type==="diary"){
        this.showDairy=true;
      }
    },
    "showSmokeAlarmWorking": function() {
      if (document.getElementsByName("smoke_alarm")[0].value === "0") {
        this.smokeAlarmWorkingNo = true;
      } else {
        this.smokeAlarmWorkingNo = false;
      }
    },
    "ypMissing": function() {
      if (document.getElementsByName("yp_missing")[0].value === "1") {
        this.showYPMissing = true; 
      } else {
        this.showYPMissing = false;
      }
    },
    "ypMissingTalk": function() {
      if (document.getElementsByName("yp_missing_talk")[0].value === "0") {
        this.ypMissingTalkNo = true;
        this.ypMissingTalkYes = false;
      } else if(document.getElementsByName("yp_missing_talk")[0].value === "1") {
        this.ypMissingTalkYes = true;
        this.ypMissingTalkNo = false;
      }else{
        this.ypMissingTalkYes = false;
        this.ypMissingTalkNo = false;
      }
    },
    showCommentMissedCallNotification :function(id){
      document.getElementById("missed_id").value = id;
      this.showCommentMissedCallNotificationYes = true;
    },

    addOffset: function(i) {
      this.itemList.push({
        name: "item_list",
        colorName:"item_list_color",
        count:i+this.addOffsetCount,
      });

      this.addOffsetCount++;
      
    },
    addMoreOffset:function(i) {
      const $this = this;
      $this.roomCheck.push({
        area_name: "area_name",
        description: "description",
        issues: "issues",
        item_list:"item_list",
        item_list_color:"item_list_color",
        documents:"document",
        count:1,
        increment:i+this.addMoreOffsetCount,
      });
      this.roomCheckCounter=1;
      this.addMoreOffsetCount++;
    },
    addMoreOffsets: function(name, roomcheck_key,i ) {
      // let incr = this.roomCheckCounter++;
      document.getElementsByClassName(name)[0].insertAdjacentHTML("beforeend", 
          "<div class=\"form-group\"> "+
          "<label class=\"form__label\">Item list</label>" +
          "<textarea" +
          " name=\"addMoreOffset[" + roomcheck_key+ "][addMoreOffsets][" + i + "][item_list]\"" +
          " class=\"form__input form__input--text form__input--wide\"" +
          " placeholder=\"Item list\" " +
          "></textarea>"+
          "</div>" +
          "<div class=\"form-group\">"+
          "<label class=\"form__label\">Condition</label>"+
          "<select name=\"addMoreOffset[" + roomcheck_key+ "][addMoreOffsets][" + i + "][item_list_color]\"" + 
          " class=\"form-control\" "+
          "onchange=\"this.style.color=this.value;\">"+
          "<option>Select Value</option>" +
          "<option value=\"darkgreen\">1-Perfect</option>" +
          "<option value=\"limegreen\">2-Excellent</option>" +
          "<option value=\"yellow\">3-Good</option>" +
          "<option value=\"orange\">4-Average</option>" +
          "<option value=\"red\">5-Bad</option>" +
          "</select>" +
          "</div>");

      // this.moreOffset.push(name);
    },
    "getDefaulAims": function() {
      this.$http.get("/monitor/ajx_default_aims",{"area_id":this.defaultAimAreaId},
       function(response) {
         this.setDefaultprimaryAims = response;
       });
    },
    "showSequeceInfo": function(squende_id) {
      this.squenceId = squende_id;
      this.showSequenceAimInfo = true;
    },
    "showCaseNotes": function(case_note_id) {
      this.caseNoteId = case_note_id;
      this.showcaseNoteInfo = true;
    },
    "showCaseNotesLogs": function(case_note_log_id) {
      const $this = this;
      $this.$http.get("casenote-logs/view",{"casenote_id":case_note_log_id},
       function(response) {
         document.getElementById("casenote_edit_id").innerHTML = response;
         this.showcaseNoteLogsInfo = true;
         $this.$compile(document);
       });
    },
    "showCustomAimInfo": function(scustom_aim_id) {
      this.customAimId = scustom_aim_id;
      this.showCustomAimPop = true;
    },
    addMoreSubOffsets: function(name, key ) {
      //this.addMoreSubOffsetsCount + i;
      const incr = document.getElementsByClassName(name)[1].value;
      document.getElementsByClassName(name)[0].insertAdjacentHTML("beforeend", 
          "<div class=\"form-group\"> "+
          "<label class=\"form__label\">Item list</label>" +
          "<textarea" +
          " name=\"addMoreOffset[" + key+ "][addMoreOffsets][" + incr + "][item_list]\"" +
          " class=\"form__input form__input--text form__input--wide\"" +
          " placeholder=\"Item list\" " +
          "></textarea>"+
          "</div>" +
          "<div class=\"form-group\">"+
          "<label class=\"form__label\">Condition</label>"+
          "<select name=\"addMoreOffset[" + key+ "][addMoreOffsets][" + incr + "][item_list_color]\"" + 
          " class=\"form-control\" "+
          "onchange=\"this.style.color=this.value;\">"+
          "<option>Select Value</option>" +
          "<option value=\"darkgreen\">1-Perfect</option>" +
          "<option value=\"limegreen\">2-Excellent</option>" +
          "<option value=\"yellow\">3-Good</option>" +
          "<option value=\"orange\">4-Average</option>" +
          "<option value=\"red\">5-Bad</option>" +
          "</select>" +
          "</div>");
      document.getElementsByClassName(name)[1].value++;
    },
    viewDBS: function(dbsId){
      this.viewDBSId = dbsId;
      this.showDBSView = true;
    },
    editDBS: function(dbsId){
      this.editDBSId = dbsId;
      this.showDBSEdit = true;
    },
    handledByFilter: function(id){
      const $this = this;
      $this.$http.get("/location/"+id,
        {"handledBy" : this.handledBy},
        function(response){
          if(response.length > 0) {
            const parser = new DOMParser();
            const doc = parser.parseFromString(response, "text/html");
            document.getElementsByClassName("maintenance_work_panel")[0].innerHTML =
              doc.getElementsByClassName("maintenance_work_panel")[0].innerHTML;
            $this.$compile(document);
          }
        });
    },
    checkinButton: function() {
      document.getElementsByClassName("title__add--checkin")[0].disabled = true;
    },
    showCaseNotesList: function(id){
      const $this = this;
      $this.$http.get("overtime",{"staff_id":id},
       function(response) {
         document.getElementById("casenote_time_id").innerHTML = response;
         this.showCaseNotesInfo = true;
         $this.$compile(document);
       });
    },
    showCaseNotesView: function(person,casenote) {
      const $this = this;
      $this.$http.get("view",{"casenote":casenote,"person":person},
       function(response) {
         document.getElementById("viewcasenote").innerHTML = response;
         this.showCaseNotesInfo = true;
         $this.$compile(document);
       });
    },
    shiftCalculationArea: function(event) {
      const areaid = event.target.value;
      const formData = { "areaid":areaid };
      const $this = this;
      $this.$http.post("/shift-calculation/area", formData, response => {
        if(response.success) {
          const stafflistlength = response.list.length;
          $this.$els.shiftcalculation_staff.style.display="block";
          $this.$els.shiftcalculation_stafflist.innerHTML = "";
          for (let i=0; i <stafflistlength; i++) {
            $this.$els.shiftcalculation_stafflist.innerHTML += " " +
            "<option value="+ response.list[i].id +">"+ response.list[i].first_name +
            " "+ response.list[i].last_name +"</option>"
            + " ";
          }

          if(stafflistlength === 0) {
            $this.$els.shiftcalculation_stafflist.innerHTML += 
            "<option>No Entries</option>";
            $this.$els.shiftcalculation_staff.style.display="none";
          }
        }

        if(response.error) {
          $this.$els.shiftcalculation_staff.style.display="none";
        }
      });
      
    },
    viewSignature:function(signature,id){
      console.log(signature);
      const $this = this;
      $this.$http.get(id+"/signed-docs-signature",{"sign":signature },
       function(response) {
         document.getElementById("signed_signature").innerHTML = response;
         this.showSignedDocsSignature = true;
         $this.$compile(document);
       });
    },
    showInspectionconfirmed :function(id){
      const $this = this;
      $this.$http.get("/virual-inspection/ajax",{"id":id },
       function(response) {
         document.getElementById("virtual_inspection_id").innerHTML = response;
         $this.showVirtualInspectionconfirmed = true;
         $this.$compile(document);
       });
    },
    "riskViewed": function(event) {
      const $this = this;
      $this.$http.get("/risk-entry/viewed/" +
        event.target.getAttribute("data-id") + "/ajax/get",
        function(content) {
          const element = document
                        .getElementsByClassName("RiskViewed")[0];
          element.innerHTML = "";
          if (content[0].status === "success") {
            for (let i = 0; i < content.length; i++) {
              element.innerHTML += "<p></p>";
              element.innerHTML += "<a>" +
                content[i].name + "</a>";

              element.innerHTML +=
              "<img height='100px' width='200px' src='"+content[i].sign+"'></img>";
            }
          } else {
            element.innerHTML = "<p>No Users</p>";
          }
          $this.showRiskViewed = true;
        });
    },
    "riskViewedPending": function(event) {
      const $this = this;
      $this.$http.get("/risk-entry/pending/" +
        event.target.getAttribute("data-id") + "/ajax/get",
        function(content) {
          const element = document
                          .getElementsByClassName("RiskViewsPending")[0];
          element.innerHTML = "";
          if (content[0].status === "success") {
            for (let i = 0; i < content.length; i++) {
              element.innerHTML += "<p>" +
                content[i].name + "</p>";
            }
          } else {
            element.innerHTML = "<p>No Users</p>";
          }
          $this.showRiskPending = true;
        });
    },
    createRiskTemplate: function(area_id){
      document.getElementById("area_id").value = area_id; 
      this.showCreateRiskTemplate = true;
    },
    supervisionAlert:function(name){
      alert({
        title: "",
        text: "Supervision of "+name+" is currently running.",
        type: "warning",
        showCancelButton: false,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "OK",
        currentProgressStep: 0}
      ); 
    },
    "onCallProtocalEdit": function(event) {
      const oncall_id = event.target.getAttribute("data-id");
      const information = event.target.getAttribute("data-information");
      document.getElementById("pc_id").value = oncall_id;
      document.getElementById("pc_information").value = information;
      document.getElementById("pc_action").value = "oncallprotocalEdit";
      this.showoncallprotocalEdit = true;
    },
    compareDateBusinessInsurance: function(){
      const inDate = document.getElementById("issue_date").value;
      const outDate = document.getElementById("expiry_date").value;
      document.getElementById("dateErrorBusinessInsurance").innerHTML="";
      if(inDate.length===0 || outDate.length===0){
        document.getElementById("dateErrorBusinessInsurance").innerHTML="Please select Issue and Expiry date";
      }else if (new Date( inDate.replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3") ) >= new Date( outDate.replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3") )){
        document.getElementById("dateErrorBusinessInsurance").innerHTML="Expiry Date should be greater than Issue date";
      }else{
        document.getElementById("BusinessInsuranceFrm").submit();
      }
    },
    viewBusinessInsurance: function(sbiId){
      this.viewBusinessInsId = sbiId;
      this.showBusinessInsView = true;
    },
    compareDateMot: function(){
      const inDate = document.getElementById("issue_date").value;
      const outDate = document.getElementById("expiry_date").value;
      document.getElementById("dateErrorMot").innerHTML="";
      if(inDate.length===0 || outDate.length===0){
        document.getElementById("dateErrorMot").innerHTML="Please select Issue and Expiry date";
      }else if(new Date(inDate) >= new Date(outDate)){
        document.getElementById("dateErrorMot").innerHTML="Expiry Date should be greater than Issue date";
      }else{
        document.getElementById("MotFrm").submit();
      }
    },
    viewMot: function(sbiId){
      this.viewMotId = sbiId;
      this.showMotView = true;
    },
    supervisionUploads: function(supervision,sdate){
      console.log(supervision);
      this.supervisionUploadsEdit = true;
      this.supervisionUploadsobj.supervision_uploads_id = supervision.id;
      this.supervisionUploadsobj.issue_date = sdate;
      this.supervisionUploadsobj.files= supervision.files;
      this.supervisionUploadsobj.viewfile = "/staff/supervision/"+supervision.id+"/uploads";
    },
    loadSeniorAboveStaff:function(){
      const $this = this; 
      const area_id =document.getElementById("area_id").value;
      document.getElementById("staff_id").innerHTML ="";
      document.getElementById("roles_staff_id").innerHTML="";
      if(area_id !== ""){
        $this.$http.get("manage-supervision/seniorabove", {"area_id":area_id},function(response) {
          document.getElementById("roles_staff_id").innerHTML =response;
          $this.$compile(document);
          document.getElementById("staff_id").innerHTML ="";
        });
      }else{
        if(area_id === ""){
          $this.alert("","Please select area.","error");
        } 
      }
    },
    loadAllStaff:function(){
      const $this = this; 
      const area_id =document.getElementById("area_id").value;
      const staff_id =document.getElementById("roles_staff_id").value;
      document.getElementById("staff_id").innerHTML ="";
      if(area_id !== "" && staff_id !== ""){
        $this.$http.get("manage-supervision/currentrolebelow", {"area_id":area_id,"staff_id":staff_id},function(response) {
          document.getElementById("staff_id").innerHTML =response;
          $this.$compile(document);
          
        });
      }else{
        if(area_id === ""){
          $this.alert("","Please select area.","error");
        }
        if(staff_id === ""){
          $this.alert("","Please select supervisor.","error");
        } 
      }
    },
    viewSupervision: function(id){
      const $this = this;
      $this.$http.get(id+"/editmanagesupervision",function(response) {
        document.getElementById("edit_staff_supervision_id").innerHTML =response;
        $this.$compile(document);
        this.showViewSupervision = true;
      });    
    },
    loadUnAssignSuppervisee(){
      const $this = this;
      document.getElementById("unassign_supervisee_list").innerHTML ="";
      const area_id =document.getElementById("unassign_area_id").value;
      
      if(area_id !== ""){
        $this.$http.get("manage-supervision/noassignmanagesupervisee", {"area_id":area_id,"no_assign_unsignstaff":"no_assign_unsignstaff"},function(response) {
          document.getElementById("unassign_supervisee_list").innerHTML =response;
          $this.$compile(document);
        });
      }else{
         
        if(area_id === ""){
          $this.alert("","Please select area.","error");
        } 
      }
    },
    onCallnotificationPdf: function(){
      this.oncallnotificationpdf = true;
      document.getElementById("on_call_notificationpdf_startdate").value="";
      document.getElementById("on_call_notificationpdf_enddate").value="";
    },
  }
};
