import Vue from "vue";

import template from "../templates/RotaGenerator.html";
import alert from "sweetalert2";

export default Vue.extend({
  template,
  props: {
    send: String,
    previous: Object,
  },
  data() {
    return {
      area_id:null,
      year:null,
      week:null,
    };
  },
  methods: {
    generate(copy = false) {
      this.$http.post(this.send, {
        copy,
      }).then((response) => {
        this.area_id = response.data.area_id;
        this.year = response.data.year;
        this.week = response.data.week;

        if(response.data.error && response.data.error!==""){
          alert({
            title: "No previous rota found",
            text: response.data.error,
            type: "error"
          });
        }else{
          alert({
            title: "Success",
            type: "success",
            currentProgressStep: 0
          }).then(() => {
            window.location = "/monitor/rota/all/"+this.area_id+"/"+this.year+"/"+this.week;
          });
        }
      });
    },
    confirm() {
      if (this.previous) { // Ask if they want to copy
        alert({
          "title": "Generate or Copy rota?",
          "text": `
            Would you like to generate a new rota or copy from last week?
          `,
          "type": "question",
          "confirmButtonText": "Copy",
          "cancelButtonText": "Generate New",
          "showCancelButton": true,
          "allowEscapeKey": true,
          "currentProgressStep": 0,
        }).then(() => {
          return "copy";
        }, response => {
          return response;
        })
        .then(response => {
          switch (response) {
          case "copy":
            return this.generate(true);
          case "cancel":
            return this.generate();
          default:
            return;
          }
        });
      } else {
        this.generate();
      }
    }
  }
});
