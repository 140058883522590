import Vue from "vue";
import template from "../templates/ShiftViewer.html";
import { SimpleModal } from "./Modal";
import DateTimePicker from "./DateTimePicker";
import moment from "moment";
import topbar from "topbar";
import alert from "sweetalert2";

export default Vue.extend({
  template,
  components: {
    SimpleModal,
    DateTimePicker,
  },
  data() {
    return {
      current: {},
      pickerConfig: {
        enableTime: true,
        time_24hr: true,
        enableSeconds: true,
      }
    };
  },
  props: [
    "shift",
  ],
  watch: {
    shift(shift) {
      if (shift) {
        this.current = Object.assign({}, shift);
        this.current.in = this.current.in.toString();
        this.current.out = this.current.out && this.current.out.toString();
      } else {
        this.current = {};
      }
    }
  },
  methods: {
    updateTime(date, which) {
      this.current[which] = moment(date).toString();
    },
    save() {
      const { in: start, out } = this.current;
      topbar.show();
      this.$http.patch(`/monitor/rota/shift/${this.current.id}`, {
        in: start,
        out,
      }).then(response => {
        this.$dispatch("updated", response.data);
      }).catch(() => {
        alert({
          title: "Error",
          text: "Something went wrong, please try again",
          type: "error",
          currentProgressStep: 0,
        });
      }).then(() => topbar.hide());

    }
  },
  filters: {
    time(date) {
      return moment(date).format("DD/MM/YYYY HH:mm:ss ZZ");
    },
    since(date) {
      return moment(date).fromNow();
    }
  }
});
