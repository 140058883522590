import Vue from "vue";

export default Vue.extend({
  ready() {
    console.log("Showing list of rotas");
  },
  methods: {
    generated({ url, data }) {
      const buttons = this.$el.querySelectorAll(".request-button");
      for (const btn of Array.from(buttons)) {
        if (btn.getAttribute("href") === url) {
          const link = document.createElement("a");
          link.textContent = "View";
          link.setAttribute("href", `/monitor/rota/view/${data.id}`);
          btn.parentNode.replaceChild(link, btn);
          break;
        }
      }
    }
  }
});
