import Vue from "vue";
import template from "../templates/RotaTimeline.html";

Vue.config.debug = true;

export default Vue.extend({
  template,
  props: {
    assignments: {
      type: Array,
      default: [],
      required: true,
    },
    day: Number,
    editable: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      making: null,
    };
  },
  methods: {
    drawManual({ pageY: start, type, which }) {
      if (!this.editable) {
        return;
      }
      if (which && which !== 1) {
        return;
      }
      const end = type === "touchstart" ? "touchend" : "mouseup";
      const move = type === "touchstart" ? "touchmove" : "mousemove";

      const { top } = this.$el.getBoundingClientRect();
      start -= top + window.scrollY;

      const listener = ({ pageY: next }) => {
        next -= top + window.scrollY;
        // Determine range
        const s = Math.min(next, start);
        const e = Math.max(next, start);

        // Convert into half an hour chunks
        const begin = Math.max(Math.round((s / 600) * 96) / 4, 0);
        const end = Math.min(Math.round((e / 600) * 96) / 4, 24);

        this.$set("making", {
          begin,
          end,
          top: (begin / 24)*100 + "%",
          height: ((end-begin) / 24)*100 + "%",
        });

      };
      const done = () => {
        if (this.making) {
          this.$dispatch(
            "assign",
            this.day,
            this.making.begin,
            this.making.end
          );
        }
        this.$set("making", null);
        window.removeEventListener(move, listener);
        window.removeEventListener(end, done);
      };
      window.addEventListener(move, listener);
      window.addEventListener(end, done);

    },
    showDetails(shift) {
      this.$dispatch("view", shift);
    },
    time({ begin, end }) {
      const pad = x => x.toString().length < 2 ? "0" + x : x;
      return `
        ${pad(Math.floor(begin) % 24)}:${pad((begin % 1) * 60)} -
        ${pad(Math.floor(end) % 24)}:${pad((end % 1) * 60)}
      `;
    },
    position({ start, end, day, user_id }) {
      if (start > end) {
        if (this.day === day) {
          end = 24;
        } else {
          start = 0;
        }
      }

      if(parseInt(this.day) > parseInt(day) && start <= end) {
        start = 0;
      }

      const staff = user_id;
      const top = (start / 24 * 100) + "%";
      const height = ((end - start) / 24 * 100) + "%";
      return { top, height, start, end, staff, padding: "0 10px" };
    },
    canFit(shift, column) {
      for (const sh of column) {
        const { start: s, end: e, staff: st } = this.position(sh);
        const { end, start, staff } = this.position(shift);
        if (
          (start >= s && start <= e) ||
          (start === s && end === e) ||
          (st !== staff)
        ) {
          return false;
        }
      }
      return true;
    }
  },
  computed: {
    columns() {
      const result = [[]];
      for (const shift of this.assignments) {
        let fit = false;
        for (const column of result) {
          shift.n = shift.staff.id % 10;
          if (this.canFit(shift, column)) {
            column.push(shift);
            fit = true;
            break;
          }
        }

        if (!fit) {
          result.push([shift]);
        }
      }

      return result;
    }
  }
});
