/**
 * Checkin Component
 * Represents a clickable link/button that will get location
 */

import Vue from "vue";

import template from "templates/Checkin.html";

export default Vue.extend({
  "props": {
    "href": {
      "type": String,
      "required": true
    },
    "name": {
      "type": String,
      "required": true
    },
    "process": {
      "type": String,
      "required": true
    },
    "class": {
      "type": String,
      "required": true
    }
  },
  "template": template,
  "data": function() {
    return {
      "checkin": null,
      "checkout": null
    };
  },
  "methods": {
    "doreq": function(){
      const httpreq = require ("httpreq");
      const options = {
        method: "POST",
        url: "https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyC4zuvqVGdPVrEA2M5B8jc8cPDbbOv9hbI",
        json: {},
        timeout: 2000
      };

      httpreq.doRequest (options, (err, res) => {
        let data = res && res.body || "";
        let error = null;

        try {
          data = JSON.parse (data);
        } catch (e) {
          error = new Error ("invalid response");
          error.error = e;
        }

        if (err) {
          error = new Error ("request failed");
          error.error = err;
        }

        if (data && data.error) {
          error = new Error ("api error");
          error.error = data.error.errors;
        }

        if (error) {
          error.statusCode = res && res.statusCode;
          //callback (error);
          console.log("error", error);
        } else {
          apiGeolocationSuccess({coords: 
          {latitude: data.location.lat, 
            longitude: data.location.lng}});
        }
      });

      const apiGeolocationSuccess = position => {
        if (this.process === "checkin") {
          this.$set("checkin", position.coords);
        } else {
          this.$set("checkout", position.coords);
        }
      };
    },
    "getLocation": function() {
      const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      };

      const success = pos => {
        if (this.process === "checkin") {
          this.$set("checkin", pos.coords);
        } else {
          this.$set("checkout", pos.coords);
        }

      };

      const error = err => {
        console.warn("ERROR(" + err.code + "): " + err.message);
        this.doreq();
      };

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(success, error, options);
      } else {
        console.warn("Geolocation is not supported by this browser.");
        this.doreq();
      }

    },
    "onSubmit":function(e){
      const youngPersonId = document.getElementById("personId").value;
      console.log(youngPersonId);
      e.target.action = "/checkout?person_id=" + youngPersonId + "&dashboard=yes";
    }
  }
});
