/**
 * Application entry point
 */

// Import and configure external modules
import Vue    from "vue";
import $http  from "vue-resource";
import topbar from "topbar";
import bindLinks from "util/bindLinks";

// Load vue plugins and configure requests to include the laravel CSRF token
Vue.use($http);
Vue.http.headers.common["X-CSRF-TOKEN"] =
  document.querySelector("meta[name=csrf-token]").content;

// Initialize Pjax and choose page elements to replace

// Fix PJAX error handling
bindLinks();

// Load global components
import "components";

//Load global Directives
//import "directives";

// Boostrap VueJs application
import common from "common";
const core = new Vue(common);

// Listen to pjax events and show/hide topbar as necessary
document.addEventListener("pjax:send", function() {
  topbar.show();
});

document.addEventListener("pjax:complete", function() {
  topbar.hide();
  // Re-run Vue compile method to bind all components
  core.$compile(core.$el);
  bindLinks(core.$el);
});

