/**
 * Bodymap Data Point Component
 *
 */

import Vue from "vue";
import alert from "sweetalert2";
import template from "templates/BodymapDataPoint.html";

export default Vue.extend({
  "props": {
    "id": {
      "type": String,
      "required": false
    },
    "person": {
      "type": String,
      "required": false
    },
    "name": {
      "type": String,
      "required": false
    },
    "class": {
      "type": String,
      "required": false,
      "default": ""
    },
    "x": {
      "type": String,
      "required": false
    },
    "y": {
      "type": String,
      "required": false
    },
    "severity": {
      "type": String,
      "required": false
    },
    "tip": {
      "type": String,
      "required": false
    },
    "image": {
      "type": String,
      "required": false
    },
    "form_action": {
      "type": String,
      "required": false,
      "default": "person"
    },
    "show_tip": {
      "type": Boolean,
      "required": true,
      "default": true
    }
  },
  "methods": {
    showBodyMapImage: function() {
      alert({
        title: "",
        text: "<p>" + this.tip + "</p><br/><p><img class='bodymap-image' " +
          "src='data:application/image;base64," +
          this.image + "'/><p>",
        html: true
      });
    },
    showImageData: function(event) {
      let tipElement = null;
      const element = event.target;
      tipElement = element.getElementsByClassName("tip-box")[0];
      if (element.getAttribute("data-tip-on") === "false") {
        if (element.getAttribute("data-tip") === "") {
          const bodymapForm = document.getElementsByClassName("bodymapForm")[0];
          document.getElementsByName("severity")[0].value = "Minor";
          bodymapForm.getElementsByClassName("form__textarea")[0].value = null;
          bodymapForm.setAttribute("action","/person/" +
            element.getAttribute("data-person-id") + "/bodymap");
          const bodymapFormTitle = bodymapForm.parentNode
            .getElementsByTagName("h1")[0];
          bodymapFormTitle.innerText = "Add to Body Map";
          if(document.getElementsByClassName("bodymapFormLink")[0]){
            document.getElementsByClassName("bodymapFormLink")[0].click();
          }
        } else {
          if(tipElement){
            tipElement.style.display = "block";
          }
          element.setAttribute("data-tip-on", "true");
        }
      } else {
        if(tipElement){
          tipElement.style.display = "none";
        }
        element.setAttribute("data-tip-on", "false");
      }
    },
    editDataPoint: function(event) {
      const element = event.target;
      const bodymapForm = document.getElementsByClassName("bodymapForm")[0];
      bodymapForm.setAttribute("action","/"+this.form_action+"/" +
        element.getAttribute("data-person-id") + "/bodymap/" +
        element.getAttribute("data-bodymap-id") + "/edit");
      const bodymapFormTitle = bodymapForm.parentNode
        .getElementsByTagName("h1")[0];
      bodymapFormTitle.innerText = "Edit Body Map";

      const bodymapPointElement = document
        .getElementById(element.getAttribute("data-bodymap-id"));

      document.getElementsByName("bodymap_x")[0]
        .value = bodymapPointElement.getAttribute("data-x");
      document.getElementsByName("bodymap_y")[0]
        .value = bodymapPointElement.getAttribute("data-y");
      document.getElementsByName("severity")[0]
        .value = bodymapPointElement.getAttribute("data-severity");
      const severityItems = document.getElementsByName("severity")[0]
        .parentNode.getElementsByTagName("li");
      for (let j = 0; j < severityItems.length; j++) {
        severityItems[j].className = "dropdown__list__item";
        if (severityItems[j].innerText.trim() === bodymapPointElement
            .getAttribute("data-severity")) {
          severityItems[j].className += " dropdown__list__item--selected";
        }
      }
      if(document.getElementsByClassName("bodymap-image")[0]){
        document.getElementsByClassName("form__imagepreview--image")[0]
          .setAttribute("src","data:application/image;base64,"+this.image);
      }
      if(this.image){
        document.getElementsByName("image")[0]
        .removeAttribute("required");
      }
      this.$root.showBodyMap =true;
      bodymapForm.getElementsByClassName("form__textarea")[0]
        .value = bodymapPointElement.getAttribute("data-tip");
      if(document.getElementsByClassName("bodymapFormLink")[0]){
        document.getElementsByClassName("bodymapFormLink")[0].click();
      }
    },
    deleteDataPoint: function(event) {
      const element = event.target;
      const frmAction = this.form_action;
      alert({
        title: "Are you sure?",
        text: "You will not be able to recover this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Yes, delete it!",
        closeOnConfirm: false
      }).then(
      function(isConfirm){
        if(isConfirm){
          window.location = "/"+ frmAction +"/" +
          element.getAttribute("data-person-id") + "/bodymap/" +
          element.getAttribute("data-bodymap-id") + "/delete";
        }
      });
    },
    hideBodyMapTip: function(event) {
      event.target.parentNode.parentNode.parentNode
        .style.display = "none";
      event.target.parentNode.parentNode.parentNode
        .parentNode.setAttribute("data-tip-on", "false");
    }
  },
  "template": template,
  "ready": function() {
  }
});
