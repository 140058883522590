/**
 * Modal Component
 * Represents a modal for form input
 */

import Vue from "vue";

import template from "templates/Modal.html";

export default Vue.extend({
  "props": {
    "show": {
      "type": Boolean,
      "required": true,
      "twoWay": true
    }
  },
  "template": template,
  methods: {
    close() {
      this.show = false;
    },
  },
});

export const SimpleModal = Vue.extend({
  "props": {
    "show": {
      "type": Boolean,
      "required": true,
    }
  },
  "template": template,
  "methods": {
    close() {
      this.$dispatch("closed");
    }
  }
});
