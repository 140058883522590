/**
 * Panel Component
 * Represents a set of collapsable modular content
 */

import Vue from "vue";

import template from "templates/Panel.html";

export default Vue.extend({
  "props": {
    "name": {
      "type": String,
      "required": true
    },
    "open": {
      "type": Boolean,
      "required": false,
      default: true
    }
  },
  /*"data": function() {
    return {
      "open": true
    };
  },*/
  "template": template,
  "methods": {
    "toggle": function() {
      this.open = !this.open;
    }
  }
});
